:root {
    --primary-gradient: linear-gradient(90deg, #D00092, #E80080, #EF007B, #B400A6, #FF904E, #FF645A);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #FFFFFF;
    color: #333333;
    line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', sans-serif;
    color: #333333;
}

a {
    text-decoration: none;
    color: inherit;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #FFFFFF;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}


.header .logo {
    display: flex;
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    background: var(--primary-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.logo-image {
    width: 60px;
    height: auto;
    margin-right: 10px;
}

/* Hide nav links and display a hamburger icon on small screens */
.nav {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
    align-self: center;
    z-index: 1000;
}

/* Medium Screens */
@media (max-width: 1024px) and (min-width: 768px) {
    .header {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .logo-image {
        width: 50px;
        margin-right: 8px;
    }

    .nav {
        display: flex;
        flex-direction: row;
        /* Keep navigation items in a row */
        justify-content: center;
        gap: 15px;
        width: 100%;
        /* Center the nav items */
        margin-top: 10px;
    }

    .nav a {
        font-size: 16px;
        text-transform: uppercase;
    }

    .cta-btn {
        padding: 10px 20px;
        font-size: 14px;
    }
}

/* Small Screens */
@media (max-width: 768px) {
    .nav {
        display: none;
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        width: 70%;
        height: 100%;
        background-color: white;
        opacity: 0.9;
        z-index: 999;
        justify-content: center;
    }

    .nav a {
        margin: 20px 0;
        font-size: 24px;
    }

    .nav.open {
        display: flex;
        transform: translateX(0);
    }

    .cta-btn {
        margin: 20px 0;
        font-size: 18px;
    }

    /* Show hamburger menu */
    .hamburger {
        display: block;
        position: fixed;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
        transform: translateX(0);
    }

    .hamburger.open {
        right: 60%;
    }
}

.nav a {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #00C4B3;
    letter-spacing: 0.8px;
}

.nav a:hover {
    color: #10323C;
}

/* Overlay for dark background */
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    background-color: rgba(16, 114, 160, 0.5);
    z-index: 998;
}

.cta-btn {
    background-color: #00C4B3;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 20px;
}

.cta-btn:hover {
    background-color: #008D9A;
}

@keyframes zoomBackground {
    0% {
        background-size: 60%;
        /* Initial zoom size */
        background-position: center center;
    }

    50% {
        background-size: 70%;
        /* Zoom in */
        background-position: center center;
    }

    100% {
        background-size: 60%;
        /* Zoom out */
        background-position: center center;
    }
}

/* Base styles for large screens (desktops) */
.hero {
    height: 100vh;
    background: url('./images/Anjani Innovations Logo-1.png') no-repeat center center;
    background-size: 1500px;
    animation: zoomBackground 10s ease-in-out infinite;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
}

/* Styles for medium screens (tablets) */
@media (max-width: 1024px) and (min-width: 768px) {
    .hero {
        justify-content: flex-start;
        padding-top: 15vh;
        height: 100vh;
    }

    .hero h1 {
        font-size: 40px;
    }

    .hero p {
        font-size: 18px;
    }
}

/* Styles for small screens (mobile) */
@media (max-width: 768px) {
    .hero {
        justify-content: flex-start;
        padding-top: 15vh;
        height: 100vh;
    }

    .hero h1 {
        font-size: 32px;
    }

    .hero p {
        font-size: 16px;
    }

    .hero-buttons {
        flex-direction: column;
        gap: 10px;
        padding: 20px;
    }
}

.hero h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
}

.hero p {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 36px;
    }

    .hero p {
        font-size: 18px;
    }
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.primary-btn {
    background-color: #1E90FF;
    color: #FFFFFF;
    padding: 15px 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.primary-btn:hover {
    background-color: #007ACC;
}

.secondary-btn {
    background-color: #00C4B3;
    color: #FFFFFF;
    padding: 15px 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.secondary-btn:hover {
    background-color: #008D9A;
}

@media (max-width: 768px) {

    .primary-btn,
    .secondary-btn {
        padding: 12px 20px;
        font-size: 14px;
    }
}

.about-us-section {
    padding: 60px 20px;
    background: linear-gradient(to bottom, #f8f9fa, #ffffff);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

.about-us-heading {
    font-size: 2.8rem;
    font-weight: 700;
    color: #10323C;
    margin-bottom: 20px;
}

.about-us-text {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.8;
    color: #666;
}

.core-values {
    margin: 40px 0;
}

.core-values-heading {
    font-size: 2rem;
    color: #10323C;
    margin-bottom: 20px;
}

.core-values-cards {
    display: grid;
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
}

/* Large and Medium Screens (2/2 Layout) */
@media (min-width: 1000px) {
    .core-values-cards {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns */
    }
}

/* Small Screens (1/1 Layout) */
@media (max-width: 1000px) {
    .core-values-cards {
        grid-template-columns: 1fr;
        /* 1 column */
    }
}

.core-value-card {
    aspect-ratio: 3 / 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
    min-height: 250px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
}

.core-value-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.core-value-card h4 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.core-value-card p {
    font-size: 1rem;
    line-height: 1.5;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .core-value-card {
        min-height: 300px;
    }
}

@media (max-width: 767px) {
    .core-value-card {
        min-height: 200px;
    }
}

.milestones {
    margin: 40px 0;
}

.milestones-heading {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #10323C;
}

.timeline {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 0 auto;
    max-width: 600px;
    text-align: left;
}

.timeline-item {
    position: relative;
    padding-left: 40px;
}

.timeline-year {
    font-weight: bold;
    color: #D00092;
    margin-bottom: 5px;
    display: block;
}

.timeline-item::before {
    content: '';
    position: absolute;
    left: 15px;
    top: 5px;
    height: 10px;
    width: 10px;
    background: #FF645A;
    border-radius: 50%;
}

.about-us-cta {
    margin-top: 40px;
}

.cta-button {
    margin-top: 20px;
    padding: 10px 20px;
    background: linear-gradient(90deg, #D00092, #FF645A);
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    transition: background 0.3s ease;
    text-decoration: none;
}

.cta-button:hover {
    background: linear-gradient(90deg, #FF645A, #D00092);
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(90deg, #D00092, #E80080, #FF645A);
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s;
}

.cta-button:hover {
    background: linear-gradient(90deg, #E80080, #FF645A, #D00092);
}

.learn-more-btn {
    font-size: 16px;
    font-weight: 600;
    color: #1E90FF;
    text-transform: uppercase;
}

.learn-more-btn:hover {
    text-decoration: underline;
}

.products {
    padding: 60px 20px;
    text-align: center;
    background-color: #F9F9F9;
}

.products h2 {
    font-size: 36px;
    color: #333333;
    font-weight: 700;
    margin-bottom: 40px;
}

.product-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
}

.product-card {
    background: linear-gradient(135deg, #ab3fa2, #e97fb9, #ea68ab, #fcaf83);
    color: #FFFFFF;
    padding: 30px;
    border-radius: 15px;
    width: 280px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
}

.product-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.product-card h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 10px 0;
    color: #e8eff1;
}

.product-card p {
    font-size: 20px;
    margin-bottom: 20px;
    color: #000000cc;
}

.learn-more-btn {
    padding: 4px 16px;
    background-color: #ab3fa2;
    color: whitesmoke;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.learn-more-btn:hover {
    background-color: #ff1eb0;
    color: #ffffff;
}

@media (max-width: 768px) {
    .products h2 {
        font-size: 28px;
    }

    .product-card {
        width: 100%;
        margin-bottom: 20px;
    }
}

/* General styling for the technologies section */
.technologies {
    padding: 60px 20px;
    text-align: center;
    background: #f9f9f9;
}

.technologies h2 {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    margin-bottom: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Carousel container */
.tech-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    padding: 10px 0;
}

/* Tech item card styling */
.tech-grid {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    padding: 10px;
    scroll-behavior: smooth;
}

.tech-item {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 120px;
    min-width: 120px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tech-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.tech-item img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.tech-item img:hover {
    transform: scale(1.1);
}

.tech-item p {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 0;
}

/* Scroll button styling */
.scroll-left,
.scroll-right {
    background-color: rgba(16, 114, 160, 0.7);
    color: white;
    border: none;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.scroll-left:hover,
.scroll-right:hover {
    background-color: rgba(16, 114, 160, 0.9);
}

.scroll-left {
    left: 10px;
}

.scroll-right {
    right: 10px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .tech-item {
        width: 100px;
        min-width: 100px;
    }
}

@media (max-width: 768px) {
    .technologies h2 {
        font-size: 28px;
    }

    .scroll-left,
    .scroll-right {
        font-size: 16px;
    }
}

.testimonials {
    padding: 60px 20px;
    background-color: #F5F5F5;
    text-align: center;
}

.testimonials h2 {
    font-size: 36px;
    font-weight: 600;
    color: #333333;
}

.testimonial-card {
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
    font-style: italic;
    color: #666666;
}

.testimonial-card p {
    font-size: 18px;
}

.cta-banner {
    padding: 60px 20px;
    text-align: center;
}

.cta-banner h2 {
    font-size: 36px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 20px;
}

.cta-banner .primary-btn,
.cta-banner .secondary-btn {
    margin-top: 20px;
}

.footer {
    padding: 40px 20px;
    background-color: #10323C;
    color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-form,
.footer-details {
    flex: 1;
    max-width: 45%;
    margin: 10px;
}

.footer h3 {
    font-size: 24px;
    font-weight: 700;
    color: #00C4B3;
    margin-bottom: 15px;
}

.footer-form label {
    display: block;
    margin-top: 10px;
    font-size: 16px;
}

.footer-form input,
.footer-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
    border: none;
    margin-bottom: 10px;
}

.submit-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00C4B3;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
}

.submit-btn:hover {
    background-color: #008D9A;
}

.spinner {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.8s linear infinite;
}

/* Animation for spinner */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.footer-divider {
    width: 2px;
    height: 500px;
    background-color: #F5F5F5;
    opacity: 0.4;
    margin: 0 20px;
}

.footer-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 600px;
}

.footer-address {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer-details p {
    display: flex;
    align-items: center;
    gap: 10px;
}

.social-media {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.footer-address-mail-phone {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.footer-mail-phone {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-item {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.contact-icon {
    color: #00C4B3;
    font-size: 24px;
}

/* Responsive styling */
@media (max-width: 1024px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-form,
    .footer-details {
        width: 100%;
        max-width: 100%;
    }

    .footer-divider {
        width: 90%;
        height: 1px;
        margin: 20px 0;
    }

    .footer-address {
        width: 50%;
    }

    .footer-details p,
    .social-media {
        align-self: flex-start;
    }

    .footer-address-mail-phone {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .footer-details address {
        line-height: 40px;
    }

    .footer-mail-phone {
        gap: 18px;
    }
}

@media (max-width: 768px) {
    .footer h3 {
        font-size: 20px;
    }

    .footer-form label,
    .footer-form input,
    .footer-form textarea,
    .footer-details p,
    .social-media a {
        font-size: 14px;
    }

    .footer-address-mail-phone {
        flex-direction: column;
        align-items: center;
    }

    .footer-details address {
        line-height: 20px;
        font-size: 12px;
    }
}

.product-loading-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f8f9fa;
    /* Light background color */
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.loading-ring {
    position: relative;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: conic-gradient(#1E90FF 0%, #f0f0f0 25%, #f0f0f0 50%, #1E90FF 75%, #1E90FF 100%);
    animation: progress-rotate 1.5s linear infinite;
}

.loading-logo {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #fff;
}

.loading-text {
    font-size: 1.2rem;
    color: #333;
    margin-top: 20px;
}

/* Keyframes for rotating the progress ring */
@keyframes progress-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.product-description-div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    background: none;
}

.product-description-div::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: var(--background-image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.05;
    z-index: -1;
    position: fixed;
}

.product-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.product-header .logo {
    display: flex;
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    background: linear-gradient(90deg, #D00092, #E80080, #EF007B, #B400A6, #FF904E, #FF645A);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

@media (max-width: 1250px) and (min-width: 768px) {

    .product-header {
        flex-direction: column;
        align-items: center;
        padding: 15px;
    }

    .product-header .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        margin-bottom: 10px;
    }

    .product-header .logo-image {
        width: 50px;
        margin-right: 8px;
    }

    .product-header .nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: clamp(10px, 1vw, 15px);
        width: 100%;
        margin-top: 10px;
    }

    .product-header .nav a {
        font-size: clamp(12px, 1.5vw, 16px);
        text-transform: uppercase;
    }

    .product-header .cta-btn {
        font-size: clamp(10px, 1.2vw, 14px);
        padding: clamp(8px, 1vw, 12px) clamp(12px, 2vw, 16px);
    }

}

.product-header .logo-image {
    border-radius: 10px;
    color: transparent;
}

.product-logo-heading-detals {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.product-logo-heading-detals.hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
}

.product-logo-heading-detals.visible {
    opacity: 1;
    transform: translateY(0);
}

.product-landing-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: 1000;
    overflow: hidden;
}

.product-logo {
    height: 150px;
    transition: height 0.5s ease;
    border-radius: 10%;
}

.product-landing-header.scrolled .product-logo {
    height: 40px;
}

.product-title {
    font-size: 3rem;
    transition: font-size 0.5s ease;
}

.product-landing-header.scrolled .product-title {
    font-size: 1.5rem;
}

.navigate-down-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #10323C;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    cursor: pointer;
    height: 80px;
    width: 80px;
    margin-top: 20px;
}

.navigate-down-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
}

.navigate-down-button img {
    height: 200%;
    width: 200%;
    transition: opacity 0.3s ease;
}

.navigate-down-button:hover img {
    opacity: 0.8;
}

.summary-page {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.summary-section {
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    padding: 30px;
    font-family: "Arial", sans-serif;
    font-size: 1.2rem;
    line-height: 1.8;
    color: #333333;
}

.summary-section h2 {
    font-size: 1.5rem;
    color: #17809f;
    margin-bottom: 15px;
}

.summary-section p {
    margin: 10px 0;
}

.section-heading {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 20px 0;
    position: relative;
    color: #10323C;
    font-family: 'Arial', sans-serif;
}


.section-heading::after {
    content: "";
    display: block;
    width: 200px;
    height: 4px;
    background: var(--primary-gradient);
    margin: 10px auto 0;
    border-radius: 2px;
}


.section-heading::after {
    animation: underline-reveal 0.6s ease-in-out forwards;
}

@keyframes underline-reveal {
    0% {
        width: 0;
    }

    100% {
        width: 60px;
    }
}

.platform-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    transition: margin 0.5s ease;
}

.platform-logos img {
    width: 120px;
    cursor: pointer;
    transition: transform 0.3s;
    border-radius: 10px;
}

.platform-logos img:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    opacity: 0.9;
}

.features-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    text-align: center;
}

.feature-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.feature-card img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
}

.feature-card h3 {
    font-size: 1.25rem;
    margin: 10px 0;
    color: #333333;
}

.feature-card p {
    font-size: 1rem;
    color: #666666;
}

@media (max-width: 768px) {
    .feature-card img {
        width: 50px;
        height: 50px;
    }
}

.features-vertical {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.feature-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    position: relative;
}

.feature-section.light {
    background-color: #ffffff;
    color: #10323C;
}

.feature-section.dark {
    background-color: #10323C;
    color: #ffffff;
}

.feature-section.dark h2 {
    color: #93FFD8;
}

.feature-content {
    flex: 1;
    max-width: 600px;
    padding: 20px;
    text-align: left;
}

.feature-content h2 {
    font-size: 2rem;
    margin-bottom: 10px;

}

.feature-content p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.feature-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-image img {
    max-width: 300px;
    height: auto;
    border-radius: 15px;
    /* Add rounded corners */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    /* Add shadow for elevation */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth transitions */
}

.feature-image img:hover {
    transform: scale(1.05);
    /* Slightly enlarge on hover */
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
    /* Increase shadow intensity on hover */
}

@media (max-width: 768px) {
    .feature-section {
        flex-direction: column;
        text-align: center;
    }

    .feature-content {
        text-align: center;
    }

    .feature-image img {
        max-width: 200px;
    }
}

.workflow-section {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 40px;
    gap: 20px;
    flex-wrap: wrap;
}

.workflow-step {
    flex: 1;
    max-width: 300px;
    text-align: center;
    background: #ffffff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.workflow-step:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.step-icon {
    width: 50px;
    height: 50px;
    background: #1E90FF;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 15px auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.workflow-step h3 {
    font-size: 1.25rem;
    margin: 10px 0;
    color: #333333;
}

.workflow-step p {
    font-size: 1rem;
    color: #666666;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .workflow-section {
        flex-direction: column;
        gap: 30px;
    }
}

.download-app-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 40px 0;
    flex-wrap: wrap;
}

.logo-container {
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo-container:hover {
    transform: scale(1.1);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.logo-container img {
    width: 80%;
    height: auto;
}

@media (max-width: 768px) {
    /* .logo-container {
        width: 100px;
        height: 100px;
    } */

    .logo-container img {
        width: 80%;
    }
}

.pricing-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 40px;
}

.pricing-card {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.pricing-card.featured {
    border: 2px solid #1E90FF;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.pricing-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.pricing-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333333;
}

.pricing-card .price {
    font-size: 2rem;
    font-weight: bold;
    color: #10323C;
    margin: 10px 0;
}

.pricing-card .price span {
    font-size: 1rem;
    font-weight: normal;
    color: #666666;
}

.pricing-card ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    /* text-align: start; */
}

.pricing-card ul li {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 10px;
}

.choose-plan-btn {
    background-color: #1E90FF;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.choose-plan-btn:hover {
    background-color: #155a9c;
}

@media (max-width: 768px) {
    .pricing-card {
        width: 100%;
    }
}

/* CTA Section Styling */
.product-cta-section {
    background-color: #10323C;
    color: #ffffff;
    text-align: center;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
}

/* Heading Styling */
.product-cta-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #93ffd8;
}

/* Paragraph Styling */
.product-cta-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #e0e0e0;
}

/* Button Container */
.product-cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

/* Button Styling */
.product-cta-button {
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease;
}

/* Primary Button */
.product-cta-button.primary {
    background-color: #1E90FF;
    color: #ffffff;
    border: 2px solid #1E90FF;
}

.product-cta-button.primary:hover {
    background-color: #155a9c;
    border-color: #155a9c;
}

/* Secondary Button */
.product-cta-button.secondary {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #1E90FF;
}

.product-cta-button.secondary:hover {
    background-color: #1E90FF;
    color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .product-cta-section {
        padding: 20px 10px;
    }

    .product-cta-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .product-cta-button {
        width: 100%;
        text-align: center;
    }
}

.privacy-policy {
    padding: 20px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.privacy-policy h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #10323C;
}

.policy-section {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.policy-section h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #10323C;
    ;
}

.policy-section p {
    font-size: 1rem;
    color: #333333;
    text-align: justify;
}

.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(135deg, #D00092, #FF645A);
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 20px;
}

.coming-soon-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.time-box {
    background: rgba(255, 255, 255, 0.2);
    padding: 20px 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.3s ease;
}

.time-box:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.time-box h2 {
    font-size: 2rem;
    margin: 0;
    color: #ffffff;
}

.time-box p {
    font-size: 1rem;
    color: #ffebf0;
    margin: 0;
}